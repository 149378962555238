.input200W {
  /* width: 200px; */
  min-width: 200px;
}
.input100W {
  min-width: 100px;
}
.input60W {
  min-width: 60px;
}
.clickable {
  cursor: pointer;
}
