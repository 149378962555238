.billAmountLabel {
  display: inline;
  font-weight: bold;
  font-size: x-large;
}

.billAmountValue {
  display: inline;
  font-weight: normal;
  font-size: x-large;
}
