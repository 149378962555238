.input {
  /* width: 200px; */
  min-width: 200px;
}
.tableResponsive {
  overflow-x: auto;
}
.clickable {
  cursor: pointer;
}
